import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { ScreenIn } from './screenInFactory'
import { LifeCycle } from '@wix/thunderbolt-symbols'

export type { AnimationDef, Actions } from './types'

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageWillMountHandler).to(ScreenIn)
	bind(LifeCycle.PageWillUnmountHandler).to(ScreenIn)
}
